import { RefObject, useEffect } from 'react';
import 'intersection-observer';

export type useIntersectorProps = {
    eInterTarget: RefObject<HTMLElement> | null,
    onIntersect: IntersectionObserverCallback,
    threshold?: number,
    rootMargin?: string,
}

export const useIntersector = ({ eInterTarget, onIntersect, threshold = 1.0, rootMargin = "0px" }: useIntersectorProps): void => {
    useEffect(() => {

        if (!eInterTarget?.current) return;

        const Target = eInterTarget.current


        const observer = new IntersectionObserver(onIntersect, {
            rootMargin,
            threshold
        });
        observer.observe(eInterTarget.current);

        return () => observer.unobserve(Target);
    }, [eInterTarget, onIntersect, threshold, rootMargin]);

}
