import React from 'react'
import { Logo } from './Logo'
import { Navigation } from './Navigation'

export const Footer: React.FC = () => (
        <footer className="Footer">
            <div className="inner">
                <Logo />
                <Navigation />
                <p className="Footer-copyright">
                    &copy; Copyright Samoa IBFC {new Date().getFullYear()}
                </p>
            </div>
        </footer>
    );
