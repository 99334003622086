import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby";
import { GetNavQuery } from "../../gatsby-graphql";


export const Navigation: React.FC = () => {

    const data = useStaticQuery<GetNavQuery>(graphql`
        query getNav{
            pages: allFile(
                filter: {sourceInstanceName: {eq: "markdown-pages"}, childMarkdownRemark: {frontmatter: {onmenu: {eq: true}}}}
                sort: {fields: childMarkdownRemark___frontmatter___navSortOrder}
            ) {
                edges {
                node {
                    id
                    childMarkdownRemark {
                        frontmatter {
                            title
                            navTitle
                            path
                            navSortOrder
                            menu_item
                        }
                    }
                }
                }
            }
            menuItems: allFile(
                filter: {sourceInstanceName: {eq: "markdown-menu-items"}}
                sort: {fields: childMarkdownRemark___frontmatter___navSortOrder}
            ) {
                edges {
                node {
                    id
                    childMarkdownRemark {
                        frontmatter {
                            title
                            path
                            navSortOrder
                        }
                    }
                }
                }
            }
        }

    `)

    const dropdownItems = data.menuItems.edges.map(item => {
        const frontmatter = item.node.childMarkdownRemark?.frontmatter
        return {
            path: frontmatter?.path,
            label: frontmatter?.title,
            sortOrder: typeof frontmatter?.navSortOrder === 'number' ? frontmatter?.navSortOrder : 99,
            isDropDown: true,

        }
    })

    const mainItems = [
        ...dropdownItems,
        ...data.pages.edges.filter(page => !page.node.childMarkdownRemark?.frontmatter?.menu_item
        ).map(page => {
            const frontmatter = page.node.childMarkdownRemark?.frontmatter
            return {
                path: frontmatter?.path,
                label: frontmatter?.title,
                sortOrder: typeof frontmatter?.navSortOrder === 'number' ? frontmatter?.navSortOrder : 99,
                isDropDown: false
            }
        })].sort((item1, item2) => item1.sortOrder - item2.sortOrder)

    return (
        <nav className="Navigation">
            <ul className="Navigation-mainList">
                <li className="Navigation-mainItem"><Link to="/"><span>Home</span></Link></li>

                {mainItems.map((item, i) => {
                    return item.isDropDown ?
                        <li className="Navigation-mainItem has-subMenu" key={i}>
                            <span>{item.label}</span>
                            <ul className="Navigation-subMenu">
                                {data.pages.edges
                                    .filter(pageItem => item.path === pageItem.node.childMarkdownRemark?.frontmatter?.menu_item)
                                    .map((e, i) =>
                                        <li key={e.node.childMarkdownRemark?.frontmatter?.path}>
                                            <Link to={`${e.node.childMarkdownRemark?.frontmatter?.path || ''}`}>
                                                <span>{e.node.childMarkdownRemark?.frontmatter?.title}</span>
                                            </Link>
                                        </li>

                                    )}
                            </ul>
                        </li>
                        :
                        <li className="Navigation-mainItem" key={i}>
                            <Link to={`${item.path || ''}`}>
                                <span>{item.label}</span>
                            </Link>
                        </li>
                })}

                <li className="Navigation-mainItem"><Link to="/events"><span>Events</span></Link></li>
                <li className="Navigation-mainItem"><Link to="/contact"><span>Contact</span></Link></li>
            </ul>
        </nav>
    );
}