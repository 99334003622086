import React from "react";
import { Header } from "./Header";
import 'assets/css/styles.min.css';
import { Footer } from "./Footer";

export type LayoutProps = {
    pageClass?: string
}

export const Layout: React.FC<LayoutProps> = ({ pageClass = "", children }) => (
    <div className={`Layout typography ${pageClass}`}>
        <main>
            {children}
        </main>
    </div>
);


export default Layout