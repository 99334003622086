import { Link } from "gatsby";
import { useIntersector } from "hooks/useIntersector";
import React, { useEffect, useRef, useState } from "react";
import { Logo } from "./Logo";
import { Navigation } from "./Navigation";
import { useLocation  } from '@reach/router'


export const Header: React.FC = () => {

    const location = useLocation()
    const eInterTarget = useRef<HTMLDivElement>(null)
    const [isIntersecting, setIsintersecting] = useState(true);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useIntersector({
        eInterTarget,
        rootMargin: "50px",
        onIntersect: ([{ isIntersecting }]) => setIsintersecting(isIntersecting)
    });

    
    useEffect(() => {
        if(mobileMenuOpen) setMobileMenuOpen(false)
    }, [location])
    
    return (
        <>
            <header className={`Header ${!isIntersecting ? "scroll" : 'no-scroll'} ${mobileMenuOpen ? "menu-open" : ""}`} >
                <div className="inner">
                    <Link to="/">
                        <Logo />
                    </Link>
                    <Navigation />
                    <button
                        className="MenuButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        aria-label="Toggle Mobile Menu">
                        <span></span>
                    </button>
                </div>
            </header>
            {mobileMenuOpen &&
                <button
                    className={`MobileExit ${mobileMenuOpen ? "menu-open" : ""}`}
                    onClick={() => setMobileMenuOpen(false)}
                    aria-label="Exit Mobile Menu">

                </button>
            }
            <div className="scroll-intersect" ref={eInterTarget}></div>

        </>
    );
}